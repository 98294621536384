body {
  font-family: 'Roboto', sans-serif;
  background-color: #F4F6F8;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  zoom: 0.8;
}

.MuiPaper-root {
  padding: 20px;
  background-color: #ffffff;
}

.MuiTextField-root {
  margin-bottom: 20px;
}

.MuiInputLabel-root {
  font-weight: bold;
  transition: all 0.2s ease;
}

.MuiInputLabel-shrink {
  font-size: 1rem;
}

.MuiButton-containedPrimary {
  background-color: #5C6AC4;
  color: white;
}

.MuiCircularProgress-root {
  margin: 20px auto;
  display: block;
}

@media (max-width: 768px) {
  .MuiPaper-root {
    padding: 10px;
  }

  .MuiTypography-h5 {
    font-size: 1.5rem;
  }
}
